import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col, Container } from 'react-bootstrap'

import styled, { keyframes } from 'styled-components'
import Obfuscate from 'react-obfuscate'
class HeroPersonal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            height: 0,
            width: 0
        }
    }

    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({height: window.innerHeight})
        }
        if (this.state.width !== window.innerWidth) {
            this.setState({width: window.innerWidth})
        }
    }


    componentDidMount() {
        console.log(this.props)
        this.setState({height: window.innerHeight, width: window.innerWidth})
        window.addEventListener('resize', this.updateDimensions)
        if (window.pageYOffset < window.innerHeight) {
            document.body.addEventListener('mousemove', (e) => {
                var X = (e.clientX * -.05 / 8);
                var Y = (e.clientY * -.05 / 8);
                var element = document.getElementsByClassName("parallax-hero-item");  
                var i;
                for (i = 0; i < element.length; i++) {
                    element[i].style.transform=`translate(${X}px, ${Y}px)`
                }
            });
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    render() {

        const Section = styled.section`
            position: relative;
            .particles {
                position: absolute;
                width: 100%;
                height: 100%;
            }
            background-image: url(${this.props.background.childImageSharp.fluid.src});
            background-size: cover;
            background-repeat: no-repeat;
        `

        const Overlay = styled.div`
            width: 100%;
            display: flex;
            align-items: center;
            background-color: rgba(0,0,0,0.5);
        `

        const HeadingBox = styled.div`
            height: 35%;
            width: 70vw;
            margin: auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            background-image: url(${this.props.heroImage.publicURL});
            background-repeat: no-repeat;
            background-position: center center;
        `

        const BottomContent = styled.div`
        position: absolute;
        width: 100%;
        bottom: -14%;
        z-index: 2;
        @media (min-width:1025px) and (max-width: 1400px) {    
            bottom: -35%;
        }
        @media (max-width:767px) {
            position: relative;
            bottom: 0;
        } 
        @media (min-width:768px) and (max-width: 1023px) {    
            bottom: -25%;
        }
    `

    const ServiceCol = styled(Col)`
        padding: 0;
        border-right: #444;
        &.no-border {
            border-right: none;
        }
        @media (max-width:500px) {
            border-right:none;
        }
    `

    const Service = styled.div`
        min-height: 300px;
        background-color: #111;
        transition: .2s;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &:hover {
            background-color: #222;
        }
    `
    const ServiceContent = styled.div`
        padding: 40px;
        color: #fff;
        p {
            font-size: 14px;
            font-weight: 300;
            color: #efefef;
        }
    `
    const ServiceHeading = styled.h4`
        font-size: 30px;
        font-weight: 400;
        font-family: Teko;
    `
    const ServiceSeparator = styled.div`
        height: 5px;
        width: 50px;
        background-color: #04e5e5;
        margin-bottom: 10px;
    `

    const ServiceIcon = styled.div`
        margin-bottom: 20px;
        img {
            max-height: 70px;
        }
    `

    const ServiceText = styled.p`
        font-size: 14px;
        font-weight: 300;
        color: #c5c5c5;
    `
    const Heading1 = styled.h1`
        font-size: 80px;
        font-family: Teko;
        line-height: 40px;
        color: #fff;
        font-weight: 400;
        text-transform: uppercase;
        @media (min-width:768px) {
            margin-left: 4px;
        }
        @media (max-width:767px) {
            margin-left: 2px;
        }
        @media (min-width:768px) and (max-width:1500px) {
            font-size: 40px;
        }
        @media (max-width:767px) {
            font-size: 20px;
            line-height: 20px;
        }
    `

    const Heading2 = styled.h2`
        font-size: 120px;
        font-weight: 700;
        text-transform: uppercase;
        background: -webkit-linear-gradient(left, #00ffff , #007a7a);
        background: -o-linear-gradient(right, #00ffff, #007a7a);
        background: -moz-linear-gradient(right, #00ffff, #007a7a);
        background: linear-gradient(to right, #00ffff , #007a7a); 
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       @media (max-width:767px) {
            font-size: 40px;
            line-height: 40px;
        }
       @media (min-width:768px) and (max-width:1500px) {
            font-size: 70px;
        }
    `
    const InfoLink = styled.a`
    color: #04e5e5;
    transition: .5s;
    &:hover {
        color: #fff;
        text-decoration: none;
    }
    `


        return (
            <Section id="home">
                <Overlay style={{height: `${this.state.width > 500 ? this.state.height : 350}px`}}>
                    <HeadingBox  className="parallax-hero-item animate">
                    </HeadingBox>
                </Overlay>
                <BottomContent>
                            <Container>
                                <Row>
                                    <ServiceCol md={6} style={{borderRight: this.state.width > 500 ? "1px solid #444" : "none"}}>
                                        <Service>
                                            <ServiceContent>
                                                <ServiceIcon>
                                                    {/* <img src={this.props.webexpertIcon.childImageSharp.fluid.src} alt="Karosseriebau" /> */}
                                                </ServiceIcon>
                                                <ServiceHeading>Öffnungszeiten</ServiceHeading>
                                                <ServiceSeparator/>
                                                <ServiceText>
                                                    Mo, Di, Mi      10:00 - 19:00 Uhr
                                                </ServiceText>
                                                <ServiceText>
                                                    Do, Fr          8:00 - 17:00 Uhr
                                                </ServiceText>
                                                <ServiceText>
                                                    (Mittagspause   15:00 - 16:00 Uhr)
                                                </ServiceText>
                                            </ServiceContent>
                                        </Service>
                                    </ServiceCol>
                                    <ServiceCol md={6} style={{borderRight: this.state.width > 500 ? "1px solid #444" : "none"}}>
                                        <Service>
                                            <ServiceContent>
                                                <ServiceIcon>
                                                        {/* <img src={this.props.mobileIcon.childImageSharp.fluid.src} alt="Lackierarbeiten" /> */}
                                                </ServiceIcon>
                                                <ServiceHeading>Kontakt</ServiceHeading>
                                                <ServiceSeparator/>
                                                <ServiceText>
                                                    <InfoLink>
                                                        Telefon: <Obfuscate style={{ color: '#fff' }} tel="069 437166"/> / <Obfuscate style={{ color: '#fff' }} tel="069 444015"/>
                                                    </InfoLink>
                                                </ServiceText>
                                                <ServiceText>
                                                    Email: <Obfuscate style={{ color: '#fff' }} email="groth1898@t-online.de"/>
                                                </ServiceText>
                                            </ServiceContent>
                                        </Service>
                                    </ServiceCol>
                                </Row>
                            </Container>
                    </BottomContent>
            </Section>
        )
    }

    shapes() {

        const MoveUp = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(-40px);
            }
        `

        const MoveDown = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(40px);
            }
        `
        const Shape = styled.img`
            position: absolute;
            height: 50px;
            &.move-up {
                animation: ${MoveUp} 3s infinite alternate;
            }
            &.move-down {
                animation: ${MoveDown} 3s infinite alternate;
            }
            @media (max-width: 767px) {
                height: 20px;
            }
        `
        


        return this.props.shapes.map((value, index) => {
            return (
                <Shape
                    style={{
                        left: `${(index+1) * 10}%`,
                        bottom: `${Math.random() *
                        (+((index+1) % 2 === 0 ? 10 : 90) - +((index+1) % 2 === 0 ? 5 : 80)) +
                        +((index+1) % 2 === 0 ? 5 : 80)}%`,
                    }}
                    key={index}
                    src={value.node.childImageSharp.fluid.src}
                    alt="shape"
                    className={Math.floor(Math.random() * 10) % 2 === 0 ? "move-up" : "move-down"}
                />
            )
        })
    }
}
export default props => (
    <StaticQuery
      query={graphql`
      query {
        background: file(relativePath: {eq: "hero.jpg"}) {
          childImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
        shapes: allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "shapes"}}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        logo_small: allFile(filter: {relativePath: {eq: "brand/car/KarosseriebauGroth.svg"}}) {
            edges {
              node {
                relativePath
                name                
                extension
                publicURL
              }
            }
          }
        logo_big: allFile(filter: {relativePath: {eq: "brand/KarosseriebauGroth.svg"}}) {
            edges {
              node {
                relativePath
                name                
                extension
                publicURL
              }
            }
          }
      }      
      `}
      render={({ background, shapes, logo_small, logo_big }) => <HeroPersonal background={background} shapes={shapes.edges} logo={logo_small.edges[0].node} heroImage={logo_big.edges[0].node}  {...props} />}
    />
  )